import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Alert,
} from '@mui/material';
import { Bar, Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

const apiUrl = process.env.REACT_APP_API_URL;

const Statistics = () => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState('');
  const [stats, setStats] = useState({
    totalHorses: 0,
    avgAge: 0,
    ageGenderCount: { STALLION: {}, MARE: {}, COLT: {}, FILLY: {} },
    genderDistribution: { STALLION: 0, MARE: 0, COLT: 0, FILLY: 0 },
    colorDistribution: {},
    breedDistribution: {},
    countryDistribution: {},
    ownerHorseCount: {},
  });

  const colorsArray = [
    '#5588bb',
    '#66bbbb',
    '#aa6644',
    '#99bb55',
    '#ee9944',
    '#444466',
    '#bb5555',
  ];

  const categorizeAgeGroup = (age) => {
    // التأكد من أن العمر أكبر من أو يساوي 0 لتجنب القيم السالبة أو المستقبلية
    if (age < 0) {
      console.error('Invalid age calculated. Age cannot be negative.');
      return 'Unknown'; // وضع تصنيف مخصص لحالات الخطأ
    }

    // توزيع الخيول على الفئات العمرية بناءً على العمر
    if (age >= 0 && age <= 3) {
      return '1-3';
    } else if (age >= 4 && age <= 8) {
      return '4-8';
    } else if (age >= 9 && age <= 12) {
      return '9-12';
    } else {
      return '>12';
    }
  };

  const categorizeHorseGender = (horse) => {
    const currentDate = new Date();
    const birthDate = new Date(horse.date_of_birth);
    let ageInYears = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      ageInYears--;
    }

    // إذا كان العمر أقل من 3 سنوات
    if (ageInYears < 3) {
      if (horse.gender === 'S') {
        return 'COLT'; // الذكر الصغير (مهر)
      } else if (horse.gender === 'M') {
        return 'FILLY'; // الأنثى الصغيرة (مهرة)
      }
    } else {
      // إذا كان العمر 3 سنوات أو أكثر
      if (horse.gender === 'S') {
        return 'STALLION'; // الذكر (حصان)
      } else if (horse.gender === 'M') {
        return 'MARE'; // الأنثى (فرس)
      }
    }

    return 'Unknown';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const [
          horsesResponse,
          ownersResponse,
          colorsResponse,
          breedsResponse,
          countriesResponse,
        ] = await Promise.all([
          axios.get(`${apiUrl}/horses`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${apiUrl}/owners`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${apiUrl}/horse_colors`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${apiUrl}/horse_breeds`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${apiUrl}/countries`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const horsesData = horsesResponse.data;
        const ownersData = ownersResponse.data;
        const colorsData = colorsResponse.data;
        const breedsData = breedsResponse.data;
        const countriesData = countriesResponse.data;

        // حساب إجمالي الخيول ومتوسط العمر
        const totalHorses = horsesData.length;
        const avgAge =
          horsesData.reduce(
            (sum, horse) =>
              sum +
              (new Date().getFullYear() -
                new Date(horse.date_of_birth).getFullYear()),
            0
          ) / totalHorses;

        // توزيع الجنس والفئات العمرية حسب الفئات الجديدة
        const ageGenderCount = { STALLION: {}, MARE: {}, COLT: {}, FILLY: {} };
        const genderDistribution = {
          STALLION: 0,
          MARE: 0,
          COLT: 0,
          FILLY: 0,
        };

        horsesData.forEach((horse) => {
          const genderLabel = categorizeHorseGender(horse);
          let gender;

          if (genderLabel.includes('MARE') || genderLabel.includes('فرس')) {
            gender = 'MARE';
          } else if (
            genderLabel.includes('STALLION') ||
            genderLabel.includes('حصان')
          ) {
            gender = 'STALLION';
          } else if (
            genderLabel.includes('COLT') ||
            genderLabel.includes('مهر')
          ) {
            gender = 'COLT';
          } else if (
            genderLabel.includes('FILLY') ||
            genderLabel.includes('مهرة')
          ) {
            gender = 'FILLY';
          }

          const age =
            new Date().getFullYear() -
            new Date(horse.date_of_birth).getFullYear();
          const ageGroup = categorizeAgeGroup(age); // تصنيف الفئة العمرية

          ageGenderCount[gender][ageGroup] =
            (ageGenderCount[gender][ageGroup] || 0) + 1;
          genderDistribution[gender] += 1;
        });

        // توزيع الألوان
        const colorDistribution = {};
        horsesData.forEach((horse) => {
          const color = colorsData.find(
            (c) => c.color_id === horse.color_id
          )?.color_english;
          if (color)
            colorDistribution[color] = (colorDistribution[color] || 0) + 1;
        });

        // توزيع السلالات
        const breedDistribution = {};
        horsesData.forEach((horse) => {
          const breed = breedsData.find(
            (b) => b.breed_id === horse.breed_id
          )?.breed_english;
          if (breed)
            breedDistribution[breed] = (breedDistribution[breed] || 0) + 1;
        });

        // توزيع الدول
        const countryDistribution = {};
        horsesData.forEach((horse) => {
          const country = countriesData.find(
            (c) => c.country_id === horse.country_of_origin_id
          )?.country_english;
          if (country)
            countryDistribution[country] =
              (countryDistribution[country] || 0) + 1;
        });

        // عدد الخيول لكل مالك
        const ownerHorseCount = {};
        horsesData.forEach((horse) => {
          const owner = ownersData.find((o) => o.owner_id === horse.owner_id);
          if (owner) {
            const ownerName = `${owner.first_name} ${owner.last_name}`;
            ownerHorseCount[ownerName] = (ownerHorseCount[ownerName] || 0) + 1;
          }
        });
        /*const calculateAge = (birthDate) => {
          const currentDate = new Date();
          const birth = new Date(birthDate);
          let age = currentDate.getFullYear() - birth.getFullYear();
          const monthDiff = currentDate.getMonth() - birth.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && currentDate.getDate() < birth.getDate())
          ) {
            age--;
          }
          return age;
        };*/

        // تحديث الحالة بالبيانات
        setStats({
          totalHorses,
          avgAge,
          ageGenderCount,
          genderDistribution,
          colorDistribution,
          breedDistribution,
          countryDistribution,
          ownerHorseCount,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(t('error_fetching_data'));
      }
    };
    fetchData();
  }, [t, i18n.language]);

  // تعريف chartOptions هنا لحل الخطأ
  const chartOptions = {
    plugins: {
      datalabels: {
        color: '#fff',
        formatter: (value) => value,
        font: { weight: 'bold' },
      },
    },
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('Statistics')}
        </Typography>

        {/* إجمالي الخيول ومتوسط العمر */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" align="center">
                  {t('total_horses')}
                </Typography>
                <Typography variant="h4" align="center">
                  {stats.totalHorses}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" align="center">
                  {t('average_age')}
                </Typography>
                <Typography variant="h4" align="center">
                  {stats.avgAge.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* توزيع الجنس والفئات العمرية حسب الجنس */}

        {/* Age and Gender Distribution Chart */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" align="center">
                  {t('age_gender_distribution')}
                </Typography>
                <Bar
                  data={{
                    labels: ['1-3', '4-8', '9-12', '>12'],
                    datasets: [
                      {
                        label: t('STALLION/COLT'),
                        data: [
                          (stats.ageGenderCount.STALLION['1-3'] || 0) +
                            (stats.ageGenderCount.COLT['1-3'] || 0),
                          stats.ageGenderCount.STALLION['4-8'] || 0,
                          stats.ageGenderCount.STALLION['9-12'] || 0,
                          stats.ageGenderCount.STALLION['>12'] || 0,
                        ],
                        backgroundColor: colorsArray[0],
                      },
                      {
                        label: t('MARE/FILLY'),
                        data: [
                          (stats.ageGenderCount.MARE['1-3'] || 0) +
                            (stats.ageGenderCount.FILLY['1-3'] || 0),
                          stats.ageGenderCount.MARE['4-8'] || 0,
                          stats.ageGenderCount.MARE['9-12'] || 0,
                          stats.ageGenderCount.MARE['>12'] || 0,
                        ],
                        backgroundColor: colorsArray[1],
                      },
                    ],
                  }}
                  options={chartOptions}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* توزيع الجنس */}
        {stats.genderDistribution && (
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {t('gender_distribution')}
                  </Typography>
                  <Doughnut
                    data={{
                      labels: [t('STALLION'), t('MARE'), t('COLT'), t('FILLY')],
                      datasets: [
                        {
                          data: [
                            stats.genderDistribution.STALLION,
                            stats.genderDistribution.MARE,
                            stats.genderDistribution.COLT,
                            stats.genderDistribution.FILLY,
                          ],
                          backgroundColor: [
                            colorsArray[0],
                            colorsArray[1],
                            colorsArray[2],
                            colorsArray[3],
                          ],
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* توزيع الألوان */}
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {t('colors_distribution')}
                  </Typography>
                  <Doughnut
                    data={{
                      labels: Object.keys(stats.colorDistribution),
                      datasets: [
                        {
                          data: Object.values(stats.colorDistribution),
                          backgroundColor: colorsArray.slice(
                            0,
                            Object.keys(stats.colorDistribution).length
                          ),
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {/* توزيع السلالات ودولة المنشأ */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {stats.breedDistribution && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {t('breed_distribution')}
                  </Typography>
                  <Bar
                    data={{
                      labels: Object.keys(stats.breedDistribution),
                      datasets: [
                        {
                          label: t('breed_distribution'),
                          data: Object.values(stats.breedDistribution),
                          backgroundColor: colorsArray.slice(
                            0,
                            Object.keys(stats.breedDistribution).length
                          ),
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}

          {stats.countryDistribution && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {t('country_of_origin')}
                  </Typography>
                  <Bar
                    data={{
                      labels: Object.keys(stats.countryDistribution),
                      datasets: [
                        {
                          label: t('country_of_origin'),
                          data: Object.values(stats.countryDistribution),
                          backgroundColor: colorsArray.slice(
                            0,
                            Object.keys(stats.countryDistribution).length
                          ),
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        {/* عدد الخيول لكل مالك */}
        {stats.ownerHorseCount && (
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {t('horses_per_owner')}
                  </Typography>
                  <Bar
                    data={{
                      labels: Object.keys(stats.ownerHorseCount),
                      datasets: [
                        {
                          label: t('horses_per_owner'),
                          data: Object.values(stats.ownerHorseCount),
                          backgroundColor: colorsArray.slice(
                            0,
                            Object.keys(stats.ownerHorseCount).length
                          ),
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default Statistics;
