import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // تأكد من تضمين هذا النمط

const apiUrl = process.env.REACT_APP_API_URL;

const RecordDetails = () => {
  const { record_id } = useParams();
  const [recordData, setRecordData] = useState(null);
  const [error, setError] = useState('');
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/vetclinic-records/${record_id}`
        );
        let recordData = response.data;

        if (typeof recordData.file_uploads === 'string') {
          recordData.file_uploads = recordData.file_uploads
            ? recordData.file_uploads.split(';')
            : [];
        }

        setRecordData(recordData);
        if (recordData.owner_id) {
          try {
            const ownerResponse = await axios.get(
              `${apiUrl}/owners/${recordData.owner_id}`
            );
            const ownerData = ownerResponse.data;

            if (ownerData) {
              // Update recordData with owner information
              setRecordData((prevData) => ({
                ...prevData,
                owner_name: `${ownerData.first_name} ${ownerData.last_name}`,
                owner_phone: ownerData.phone || 'N/A',
              }));
            } else {
              console.warn('No owner data found for the given owner_id');
              setRecordData((prevData) => ({
                ...prevData,
                owner_name: 'Owner data not found',
                owner_phone: 'N/A',
              }));
            }
          } catch (error) {
            console.error('Error fetching owner data:', error);
            setRecordData((prevData) => ({
              ...prevData,
              owner_name: 'Error fetching owner data',
              owner_phone: 'N/A',
            }));
          }
        } else {
          console.warn('No owner_id found in the record data');
          setRecordData((prevData) => ({
            ...prevData,
            owner_name: 'No owner assigned',
            owner_phone: 'N/A',
          }));
        }
      } catch (err) {
        console.error('Failed to fetch record details:', err);
        setError('Failed to load record details');
      }
    };

    fetchRecordDetails();
  }, [record_id]);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!recordData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Vet Clinic Record Details
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: 4 }} />

      <Grid container spacing={3}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record ID</Typography>
                <Typography>{recordData.record_id || 'N/A'}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record Date</Typography>
                <Typography>
                  {new Date(recordData.record_date).toLocaleString('en-US', {
                    hour12: true,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }) || 'N/A'}{' '}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Horse Details</Typography>
                <Typography>Name: {recordData.horse_name || 'N/A'}</Typography>
                <Typography>
                  RFID Chip Number: {recordData.rfid_chip_number || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Owner Details</Typography>
                <Typography>Name: {recordData.owner_name || 'N/A'}</Typography>
                <Typography>
                  Phone: {recordData.owner_phone || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Procedures</Typography>
              {recordData.procedures && recordData.procedures.length > 0 ? (
                <ul>
                  {recordData.procedures.map((procedure, index) => (
                    <li key={index}>
                      {procedure.procedure_name} - {procedure.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No procedures selected</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Medications</Typography>
              {recordData.medications && recordData.medications.length > 0 ? (
                <ul>
                  {recordData.medications.map((med, index) => (
                    <li key={index}>
                      {med.medication_name} - {med.dosage} {med.unit} -{' '}
                      {med.quantity} unit(s) - {med.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No medications provided</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Amount</Typography>
              <Typography>{recordData.total_amount} SAR</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Payment Type</Typography>
              <Typography>{recordData.payment_type_name || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Notes</Typography>
              <Typography>{recordData.notes || 'No notes provided'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {recordData.file_uploads && recordData.file_uploads.length > 0 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Uploaded Files</Typography>
                <Grid container spacing={2}>
                  {recordData.file_uploads.map((file, index) => (
                    <Grid item xs={4} sm={3} md={2} key={index}>
                      <img
                        src={`${apiUrl}/uploads/${file}`}
                        alt={`Uploaded file ${index}`}
                        style={{ width: '100%', cursor: 'pointer' }}
                        onClick={() => {
                          setPhotoIndex(index);
                          setLightboxOpen(true);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}

        {lightboxOpen && (
          <Lightbox
            mainSrc={`${apiUrl}/uploads/${recordData.file_uploads[photoIndex]}`}
            nextSrc={`${apiUrl}/uploads/${
              recordData.file_uploads[
                (photoIndex + 1) % recordData.file_uploads.length
              ]
            }`}
            prevSrc={`${apiUrl}/uploads/${
              recordData.file_uploads[
                (photoIndex + recordData.file_uploads.length - 1) %
                  recordData.file_uploads.length
              ]
            }`}
            onCloseRequest={() => setLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + recordData.file_uploads.length - 1) %
                  recordData.file_uploads.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % recordData.file_uploads.length)
            }
          />
        )}

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Doctor Name</Typography>
              <Typography>{recordData.doctor_name || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecordDetails;
